
function galeria( imagesData ) {

  // console.log(imagesData);
  

  var gallery = document.querySelector('.gallery');
  var galleryItems = document.querySelectorAll('.gallery-item');
  // console.log(galleryItems.length);
  
  var numOfItems = gallery.children.length;
  var itemWidth = 23; // percent: as set in css


  var featured = document.querySelector('.featured-item');
  // var featured = document.querySelectorAll('.featured-item');  

  var leftBtn = document.querySelector('.move-btn.left');
  var rightBtn = document.querySelector('.move-btn.right');
  var leftInterval;
  var rightInterval;

  var scrollRate = 0.2;
  var left;


  ///*******************************MODAL*************************/

  // Get the modal
  var modal = document.getElementById('myModal');

  // Get the image and insert it inside the modal - use its "alt" text as a caption
  var img = document.getElementById('myImg');
  var modalImg = document.getElementById("img01");


  img.onclick = function () {
    modal.style.display = "block";
    // modalImg.src = this.src;
    modalImg.style.backgroundImage = this.style.backgroundImage;
  }

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];

  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = "none";
  }

  /*********************************************************************************/



  function selectItem(e) {
    
    if (e.target.classList.contains('active')) return;
    // featured.style.backgroundImage = e.target.style.backgroundImage;
    // featured.src = e.target.src;
    // featured[0].style.backgroundImage =  'url(' + e.target.src + ')';
    featured.style.backgroundImage =  'url(' + e.target.src + ')';
   

    for (var i = 0; i < galleryItems.length; i++) {
      if (galleryItems[i].classList.contains('active'))
        galleryItems[i].classList.remove('active');
    }

    e.target.classList.add('active');
  }

  function galleryWrapLeft() {
    var first = gallery.children[0];
    gallery.removeChild(first);
    gallery.style.left = -itemWidth + '%';
    gallery.appendChild(first);
    gallery.style.left = '0%';
  }

  function galleryWrapRight() {
    var last = gallery.children[gallery.children.length - 1];
    gallery.removeChild(last);
    gallery.insertBefore(last, gallery.children[0]);
    gallery.style.left = '-23%';
  }

  function moveLeft() {
    left = left || 0;

    leftInterval = setInterval(function () {
      gallery.style.left = left + '%';

      if (left > -itemWidth) {
        left -= scrollRate;
      } else {
        left = 0;
        galleryWrapLeft();
      }
    }, 1);
  }

  function moveRight() {
    //Make sure there is element to the leftd
    if (left > -itemWidth && left < 0) {
      left = left - itemWidth;

      var last = gallery.children[gallery.children.length - 1];
      gallery.removeChild(last);
      gallery.style.left = left + '%';
      gallery.insertBefore(last, gallery.children[0]);
    }

    left = left || 0;

    leftInterval = setInterval(function () {
      gallery.style.left = left + '%';

      if (left < 0) {
        left += scrollRate;
      } else {
        left = -itemWidth;
        galleryWrapRight();
      }
    }, 1);
  }

  function stopMovement() {
    clearInterval(leftInterval);
    clearInterval(rightInterval);
  }

  leftBtn.addEventListener('mouseenter', moveLeft);
  leftBtn.addEventListener('mouseleave', stopMovement);
  rightBtn.addEventListener('mouseenter', moveRight);
  rightBtn.addEventListener('mouseleave', stopMovement);


  //Start this baby up
  (function init() {
  
    // var images = [
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/car.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/city.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/deer.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/flowers.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/food.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/guy.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/landscape.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/lips.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/night.jpg',
    //   'https://s3-us-west-2.amazonaws.com/forconcepting/800Wide50Quality/table.jpg'
    // ];

    //Set Initial Featured Image
    // featured.src = images[0];

    // for (let index = 0; index < featured.length; index++) {
      featured.style.backgroundImage = 'url(' + imagesData[0].url + ')';  
    // }


    //Set Images for Gallery and Add Event Listeners
    for (var i = 0; i < galleryItems.length; i++) {
      // galleryItems[i].src = images[i];
      console.log(imagesData[i]);
      
      galleryItems[i].src = imagesData[i].url;
      galleryItems[i].addEventListener('click', selectItem);
    }
  })();
}
